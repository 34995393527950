import React from "react"

import Heading from "../common/Heading"

import "./about.css"

const About = () => {
  return (
    <>
      <section className='about'>
        
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='Beneficios' subtitle='' />

            <p>Somos una consultoría especializada en migración y trabajo en Polonia. Nuestros servicios incluyen conocimiento especializado y asesoramiento personalizado para nuestros clientes. Gestionamos todos los trámites y procedimientos necesarios,</p>
            <p>agilizando los procesos y minimizando errores. Nuestro objetivo es brindarte tranquilidad y confianza durante todo el proceso, asegurando una experiencia exitosa en tu migración y búsqueda laboral en Polonia.</p>
            <button className='btn2'>More About Us</button>
          </div>
          <div className='right row'>
            <img src='./immio.jpg' alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default About
