export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "Nosotros",
    path: "/about",
  },
  {
    text: "Servicios",
    path: "/services",
  },
  {
    text: "Beneficios",
    path: "/beneficios",
  },
  {
    text: "Planes",
    path: "/pricing",
  },
  {
    text: "Contactanos",
    path: "/contact",
  },
]
export const featured = [
  {
    cover: "../images/hero/h1.png",
    name: "Asesoramiento y evaluación",
    total: "Evaluación personalizada, asesoramiento estratégico.",
  },
  {
    cover: "../images/hero/h2.png",
    name: "Trámites migratorios",
    total: "Gestión eficiente de permisos y residencia.",
  },
  {
    cover: "../images/hero/h3.png",
    name: "Búsqueda de empleo",
    total: "Apoyo en búsqueda laboral, preparación entrevistas.",
  },
  {
    cover: "../images/hero/h4.png",
    name: "Relocalización empresarial",
    total: "Asesoramiento y apoyo en reubicación empresarial.",
  },
  {
    cover: "../images/hero/h6.png",
    name: "Cumplimiento legal y laboral",
    total: "Orientación legal, cumplimiento normativo laboral.",
  },
]
export const list = [
  {
    id: 1,
    cover: "../images/list/p-1.png",
    name: "Red Carpet Real Estate",
    location: "210 Zirak Road, Canada",
    category: "For Rent",
    price: "$3,700",
    type: "Apartment",
  },
  {
    id: 2,
    cover: "../images/list/p-2.png",
    name: "Fairmount Properties",
    location: "5698 Zirak Road, NewYork",
    category: "For Sale",
    price: "$9,750",
    type: "Condos",
  },
  {
    id: 3,
    cover: "../images/list/p-7.png",
    name: "The Real Estate Corner",
    location: "5624 Mooker Market, USA",
    category: "For Rent",
    price: "$5,860",
    type: "Offices",
  },
  {
    id: 4,
    cover: "../images/list/p-4.png",
    name: "Herringbone Realty",
    location: "5621 Liverpool, London",
    category: "For Sale",
    price: "$7,540",
    type: "Homes & Villas",
  },
  {
    id: 5,
    cover: "../images/list/p-5.png",
    name: "Brick Lane Realty",
    location: "210 Montreal Road, Canada",
    category: "For Rent",
    price: "$4,850",
    type: "Commercial",
  },
  {
    id: 6,
    cover: "../images/list/p-6.png",
    name: "Banyon Tree Realty",
    location: "210 Zirak Road, Canada",
    category: "For Sale",
    price: "$2,742",
    type: "Apartment",
  },
]
export const awards = [
  {
    icon: <i class='fa-solid fa-trophy'></i>,
    num: "32 M	",
    name: "Blue Burmin Award",
  },
  {
    icon: <i class='fa-solid fa-briefcase'></i>,
    num: "43 M",
    name: "Mimo X11 Award",
  },
  {
    icon: <i class='fa-solid fa-lightbulb'></i>,
    num: "51 M",
    name: "Australian UGC Award",
  },
  {
    icon: <i class='fa-solid fa-heart'></i>,
    num: "42 M",
    name: "IITCA Green Award",
  },
]
export const location = [
  {
    id: 1,
    name: "New Orleans, Louisiana",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-1.png",
  },
  {
    id: 2,
    name: "Jerrsy, United State",
    Villas: "12 Villas",
    Apartments: "10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-2.png",
  },
  {
    id: 3,
    name: "Liverpool, London",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-3.png",
  },
  {
    id: 4,
    name: "NewYork, United States",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-4.png",
  },
  {
    id: 5,
    name: "Montreal, Canada",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-5.png",
  },
  {
    id: 6,
    name: "California, USA",
    Villas: "12 Villas",
    Apartments: " 10 Apartments",
    Offices: "07 Offices",
    cover: "./images/location/city-6.png",
  },
]
export const team = [
  {
    list: "50",
    cover: "../images/customer/team-1.jpg",
    address: "Liverpool, Canada",
    name: "Sargam S. Singh",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "70",
    cover: "../images/customer/team-2.jpg",
    address: "Montreal, Canada",
    name: "Harijeet M. Siller",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "80",
    cover: "../images/customer/team-3.jpg",
    address: "Denever, USA",
    name: "Anna K. Young",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "51",
    cover: "../images/customer/team-4.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "42",
    cover: "../images/customer/team-5.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
  {
    list: "38",
    cover: "../images/customer/team-5.jpg",
    address: "Montreal, USA",
    name: "Adam K. Jollio",
    icon: [<i class='fa-brands fa-facebook-f'></i>, <i class='fa-brands fa-linkedin'></i>, <i class='fa-brands fa-twitter'></i>, <i class='fa-brands fa-instagram'></i>],
  },
]
export const price = [
  {
    best: "Best Value",
    plan: "Standard",
    price: "$8.275.000",
    ptext: "Tiempo total del proceso: 1 a 2 meses",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Honorarios Administrativos: $1.575.000",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Seguro de Asistencia Médica Internacional: $600.000 - $1.200.000",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "TKT Aéreo (ida y vuelta): Desde $5.500.000 COP / $1.712 USD",
      },
    ],
  },
  {
    plan: "Platinum",
    price: "$7.971.000",
    ptext: "Tiempo total del proceso: 4 a 6 meses",
    list: [
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Honorarios Administrativos: $1.875.000",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Tiempo de espera del permiso de trabajo: 45 a 90 días",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Tasa Consular Aplicación al Visado: $370.000 (80 EUR)",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Tiempo de espera de la visa: 8 días a 2 meses",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "TKT Aéreo (solo ida): Desde $3.950.000",
      },
      {
        icon: <i class='fa-solid fa-check'></i>,
        text: "Seguro de Asistencia Médica Internacional: $1.028.000 - $1.776.000",
      },
    ],
  },
  {
    
    list: [
      
    ],
  },
]
export const footer = [
  {
    title: "LAYOUTS",
    text: [{ list: "Home Page" }, { list: "About Page" }, { list: "Service Page" }, { list: "Property Page" }, { list: "Contact Page" }, { list: "Single Blog" }],
  },
  {
    title: "ALL SECTIONS",
    text: [{ list: "Headers" }, { list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }, { list: "Footers" }],
  },
  {
    title: "COMPANY",
    text: [{ list: "About" }, { list: "Blog" }, { list: "Pricing" }, { list: "Affiliate" }, { list: "Login" }, { list: "Changelog" }],
  },
]
