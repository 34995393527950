import React from "react"
import Back from "../common/Back"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import "./about.css"

const About = () => {
  return (
    <>
      <section className='about'>
        <Back name='About Us' title='About Us - Who We Are?' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='St. Millan Consulting' subtitle='Check out our company work process' />

            <p>Brindamos asesoramiento personalizado en migración y empleo en Polonia. Evaluamos la situación de cada cliente y los guiamos en los requisitos legales y procedimientos necesarios para alcanzar sus metas migratorias y laborales.</p>
            <p>Facilitamos los trámites migratorios y ofrecemos apoyo en la búsqueda de empleo. Preparamos la documentación, presentamos las solicitudes y seguimos de cerca los procedimientos. También asesoramos en la reubicación empresarial, asegurando el cumplimiento legal y laboral para la expansión de empresas en Polonia.</p>
            <button className='btn2'>More About Us</button>
          </div>
          <div className='right row'>
            <img src='./immio.jpg' alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default About
